<div class="tr-modal-wrapper">
    <div class="modal fade show d-block tr-modal level-up-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <h4 class="mb-0 font-bold">Congratulation!</h4>
                    <h4 class="mb-3 font-bold">You reached Level 3</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="user-profile">
                                <div class="user-profile-image">
                                    <img class="user-image" [src]="userProfileImage" alt="profile image" *ngIf="userProfileImage">
                                    <div class="user-level-badge">
                                        <img src="../../../../assets/img/level-badge.svg" alt="level badge">
                                        <div class="position-relative">
                                            <span class="level-text">LV<span class="font-bold level-number">3</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="next-challenge d-flex align-items-center">
                                <div class="level-badge">
                                    <img src="../../../../assets/img/next-level-badge-gray.svg">
                                    <span class="next-level-text">LV<span class="font-bold">4</span></span>
                                </div>
                                <div class="challenge-text font-bold">
                                    <span>Next Challenge:</span>
                                    <span>You have to add 3 Wines to your cellarbook.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-left">
                            <img class="level-up-glass" src="../../../../assets/img/level-up-glasses.svg">
                        </div>
                    </div>
                    <button type="button" class="btn tr-btn-primary text-uppercase mt-4" (click)="closePopup()">Ok, Great!!!</button>
                </div>
            </div>
        </div>
    </div>
    <div class="backdrop"></div>
</div>