import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate{
private authStatusSource = new BehaviorSubject<boolean>(false);
currentAuthStatus = this.authStatusSource.asObservable();
private userSource = new BehaviorSubject<object>({});
currentUser = this.userSource.asObservable();
private userNameSource = new BehaviorSubject<string>('');
currentUserName = this.userNameSource.asObservable();
private firstNameSource = new BehaviorSubject<string>('');
currentFirstName = this.firstNameSource.asObservable();
private imageSource = new BehaviorSubject<string>('');
currentImage = this.imageSource.asObservable();

paymentDetails: any = {};

  constructor(private router: Router,
    public amplifyService: AmplifyService) { }

  canActivate(): Promise < boolean > {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser({
          bypassCache: false
        })
        .then((user) => {
          if(user){
            localStorage.setItem("access_token", user.signInUserSession.accessToken.jwtToken);
            this.firstNameSource.next((user.attributes['custom:userType'] == "Business User") ? user.attributes['custom:companyName'] : (user.attributes.name).split(" ",1));
            this.userSource.next(user);
            if ((user.attributes.hasOwnProperty('picture') && (user.attributes.picture !== 'No photo uploaded.'))) {
              this.amplifyService.storage().get(user.attributes.picture, {
                level: 'protected',
                contentType: 'image/*'
              }).then(result => {
                this.changeImage(result);
              }).catch(err => {
                console.log(err);
              });
            } else {
              this.changeImage((user.attributes['custom:userType'] == "Business User") ? './assets/img/business-profile.png' : './assets/img/profile.jpg');
            }
            if (!(user.attributes.hasOwnProperty('custom:userType'))) {
              this.authStatusSource.next(false);
              this.router.navigate(['/federated-user-details'], {queryParams: { 'firstTimeLogin': true}});
              resolve(true);
            } else {
              this.authStatusSource.next(true);
              this.paymentDetails = JSON.parse(user.attributes['custom:paymentInfo']);
              // if ((user.attributes['custom:accountType'] == 'Premium Version') && (this.paymentDetails.paymentStatus == 'unPaid')) {
              //   this.router.navigate(['/payment']);
              // }
              resolve(true);
            }
          }
        })
        .catch(() => {
          this.authStatusSource.next(false);
          window.location.assign('/home');
          resolve(false);
        });
    });
  }
  changeUserDetails(userName: string){
    this.userNameSource.next(userName);
  }
  changeUser(user: object){
    this.userSource.next(user);
  }
  changeUserFirstName(firstName: string){
    this.firstNameSource.next(firstName);
  }
  changeAuthStatusDetails(authStatus: boolean){
    this.authStatusSource.next(authStatus);
  }
  changeImage(imageUrl: string){
    this.imageSource.next(imageUrl);
  }
  
}
