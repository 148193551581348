<a class="dropdown">
  <span class="icon notification-icon" (click)="showHideNotifications()">
    <mat-icon tooltip="{{'header.notification' | translate }}">notifications</mat-icon>
    <span class="count tr-shadow" *ngIf="notifications.length">{{ notifications.length }}</span>
  </span>

  <div class="dropdown-menu message-container tr-bg-fourth" *ngIf="showNotifications" (clickOutside)="onClickOutNotifications()" [exclude]="'.notification-icon'">
    <ul>
      <li class="clear-all-option d-flex align-items-center justify-content-end" *ngIf="notifications.length">
        <span (click)="clearAllNotifications()">
          {{ 'common.clear_all_notifications' | translate }}
        </span>
      </li>
      <li class="d-flex align-items-center justify-content-between" *ngFor="let notification of notifications; let i = index">
        <a class="notification" (click)="handleNotification(notification, i)">
          {{ notification.label }}
          <span class="time-duration">{{ notification.createdTimestamp | timeDuration }}</span>
        </a>
        <span class="clear-icon" tooltip="Clear Notification" (click)="markAsReadAndRemove(notification, i)">
          <i class="fa fa-minus-circle" aria-hidden="true"></i>
        </span>
      </li>
      <li class="no-notification-msg text-white font-bold" *ngIf="!notifications.length">{{ 'common.no_notifications_msg' | translate }}</li>
    </ul>
  </div>
</a>
