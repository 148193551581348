import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';

@Component({
  selector: 'app-level-up',
  templateUrl: './level-up.component.html',
  styleUrls: ['./level-up.component.css']
})
export class LevelUpComponent implements OnInit {
  @Input() levelData: any = {};
  @Output() onClosePopup = new EventEmitter();
  userProfileImage;
  challengeList = [
    {
      level: 4,
      challengeText: 'You have to add 3 Wines to your cellarbook.'
    }
  ]

  constructor(
    public authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentImage.subscribe(imageUrl => (this.userProfileImage = imageUrl));
  }

  closePopup(): void {
    this.onClosePopup.emit();
  }
}
