<div class="container">
    <div class="row form-div">
        <div id="resetPassword" class="col-sm-10 col-md-8 col-lg-5 p-4 my-3 allFormBox">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onRPSubmit()">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <small id="passwordHelp" class="form-text text-muted font-bold">{{ 'login.reset_code_msg' | translate }} {{ userName }}.</small>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <mat-form-field class="white-field">
                            <mat-label>{{ 'login.reset_code' | translate }}</mat-label>
                            <input type="password" matInput formControlName="code" placeholder="{{'login.enter_reset_code' | translate}}" autocomplete="off" required>
                            <mat-error>
                                <div *ngIf="resetDetails.code.errors.required">{{ 'login.reset_code_warning' | translate }}</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-sm-12">
                        <mat-form-field class="white-field">
                            <mat-label>{{ 'login.new_password' | translate }}</mat-label>
                            <input type="password" matInput formControlName="new_password" placeholder="{{'login.enter_new_password' | translate}}" autocomplete="new-password" required>
                            <mat-error>
                                <div *ngIf="resetDetails.new_password.errors?.required">
                                    {{ 'login.password_required_warning' | translate }}
                                </div>
                                <div *ngIf="!resetDetails.new_password.errors?.required && resetDetails.new_password.errors?.minlength">{{ 'login.password_length_msg' | translate }}</div>
                                <div *ngIf="!resetDetails.new_password.errors?.required && !resetDetails.new_password.errors?.minlength && !resetDetails.new_password.errors?.validatePassword?.valid">{{ 'login.invalid_password' | translate }}</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="row custom-row">
                            <span class="font-bold">
                                {{ 'login.policy' | translate }}:
                            </span>
                            <ul class="policy-statement">
                                <li>{{ 'login.password_policy_1' | translate }}</li>
                                <li>{{ 'login.password_policy_2' | translate  }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-sm-12 btn-position">
                            <button type="submit" class="btn btn-sm allButtons tr-btn-primary">{{ 'login.submit' | translate }}</button>
                            <button type="reset" routerLink="/login" class="btn btn-sm allButtons cancelResetBtn tr-btn-secondary">{{ 'login.cancel' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>