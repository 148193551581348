/* eslint-disable max-len */
/* eslint-disable lines-between-class-members */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Hub, Auth } from 'aws-amplify';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { AuthenticationService } from './sharedServices/authentication.service';
import { Subscription } from 'rxjs';
import LoadingIndicatorService from './sharedServices/loading-indicator.service';
import { MatDialog } from '@angular/material/dialog';
import LoadingIndicatorComponent from './shared/components/loading-indicator/loading-indicator.component';
import UserDataService from './sharedServices/user-data.service';
import HttpService from './sharedServices/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export default class AppComponent implements OnInit, OnDestroy {
  authStatus: boolean = false;
  showSessionModal = false;
  viewLoaded = false;
  loaderSub = new Subscription();
  loaderOn = false;
  dialogRef;
  noMarginBottom = false;
  showLevelUpPopup = false;
  showVinoPopup = false;
  levelUpData = {};

  constructor(
    private router: Router,
    private userIdle: UserIdleService,
    public translate: TranslateService,
    public data: AuthenticationService,
    private meta: Meta,
    private loadingIndicator: LoadingIndicatorService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private httpService: HttpService,
  ) {
    Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signIn' || event === 'cognitoHostedUI') {        
        this.data.changeAuthStatusDetails(this.authStatus);
        // this.ngZone.run(() => this.router.navigate(['/dashboard'])).then();
      } else {
        this.data.changeAuthStatusDetails(this.authStatus);
      }
    });

    this.meta.addTags([
      { name: 'description', content: 'Tresorvino: This is the description for the Tresorvino' },
      { name: 'keywords', content: 'tresorvino, wine, red wine' },
      { name: 'og:title', content: 'Tresorvino' },
      { name: 'og:description', content: 'Tresorvino: This is the description for the Tresorvino' },
      { name: 'og:image', content: `${window.location.origin}/assets/img/tresorvino-glass.png` },
      { name: 'og:url', content: window.location.origin },
      // { name: 'twitter:title', content: 'Tresorvino' },
      // { name: 'twitter:description', content: 'Tresorvino: This is the description for the Tresorvino' },
      // { name: 'twitter:image', content: window.location.origin + '/assets/img/tresorvino-glass.png' },
      { name: 'twitter:card', content: window.location.origin },
      { name: 'og:site_name', content: 'Tresorvino' },
      { name: 'twitter:card', content: window.location.origin },
    ]);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/login' || event.urlAfterRedirects === '/registration' || event.urlAfterRedirects === '/forgot-password') {
          this.noMarginBottom = true;
        } else {
          this.noMarginBottom = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.data.currentAuthStatus.subscribe((authStatus) => {
      if (authStatus) {
        this.authStatus = true;
        this.userIdle.startWatching();
      } else {
        this.authStatus = false;
      }
      setTimeout(() => {
        this.viewLoaded = true;
      }, 1000);
    });
    this.userIdle.onTimerStart().subscribe(() => {});

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      if (this.authStatus) {
        this.showSessionModal = true;
      }
    });

    this.loaderSub = this.loadingIndicator.loaderState.subscribe((state) => {
      if (state && !this.loaderOn) {
        this.dialogRef = this.dialog.open(LoadingIndicatorComponent, { disableClose: true });
      }
      if (!state && this.loaderOn) {
        this.dialogRef.close();
      }
      this.loaderOn = state;
    });
    // this.router.navigate(['/introduction']);

    this.userDataService.getUserAccess$.subscribe(() => {
      this.getUserAccessInfo();
    });
    const userAccessInfo = this.userDataService.getUserAccessInfo();
    if (!userAccessInfo?.length) {
      this.getUserAccessInfo();
    }
  }

  getUserAccessInfo(): void {
    this.httpService.getUserAccessInfo().subscribe((data: any) => {
      this.userDataService.setUserAccessInfo(data);
    });
  }

  onLogOut() {
    Auth.signOut({ global: true })
      .then(() => {
        this.authStatus = false;
        this.showSessionModal = false;
        this.userIdle.resetTimer();
        window.location.assign('/home');
        setTimeout(() => {
          this.clearStorages();
        }, 1000);
      })
      .catch((err) => console.log(err));
  }

  keepLoggedIn() {
    this.showSessionModal = false;
    this.userIdle.resetTimer();
  }

  clearStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  closeLevelUpPopup(): void {
    this.showLevelUpPopup = false;
  }

  closeVinoCoinPopup(): void {
    this.showVinoPopup = false;
  }
  
  ngOnDestroy(): void {
    this.loaderSub.unsubscribe();
  }
}
