import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router'
import { AuthenticationService } from '../../sharedServices/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent implements OnInit {

  verifyEmailForm: FormGroup;
  submitted = false;
  userName: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private data: AuthenticationService,
    private storageService: StorageService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.data.currentUserName.subscribe((userName) => {
      this.userName = userName;
      const localUserName = this.storageService.getLocalStorage('emailToBeVerified');
      if (!this.userName && localUserName) {
        this.userName = localUserName;
      }
      if (!this.userName) {
        this.router.navigate(["/login"]);
      }
    });
    this.verifyEmailForm = this.formBuilder.group({
      verifycode: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  onVESubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.verifyEmailForm.invalid) {
      return;
    }

    this.onVerify(this.verifyEmailForm.value.verifycode);

  }

  get verificationDetails() {
    return this.verifyEmailForm.controls;
  }

  onVerify(verifycode: HTMLInputElement) {
    // After retrieving the confirmation code from the user
    Auth.confirmSignUp(this.userName, this.verifyEmailForm.value.verifycode, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    }).then(data => {
      localStorage.setItem('firstLogin', 'TRUE');
      this.storageService.removeLocalStorage('emailToBeVerified');
      this.toastService.showSuccess(this.translate.instant("login.verification_success"));
      this.onReset();
      this.router.navigate(["/login"]);
    }).catch(err => {
      if (err.message == "Invalid verification code provided, please try again." || err.code == 'CodeMismatchException') {
        this.toastService.showError(this.translate.instant("login.valid_code_msg"));
      } else if (err.code == 'ExpiredCodeException') {
        this.toastService.showError(this.translate.instant('login.expired_verification_code_error'));
        Auth.resendSignUp(this.userName).then(() => {
          this.toastService.showSuccess(this.translate.instant('login.code_resent_successfully'));
        }).catch((e) => {
          console.log(e);
        });
      } else {
        alert(this.translate.instant("login.code_empty_warning"));
      }
    });
  }

  onReset() {

    this.verifyEmailForm.reset();

  }
}
