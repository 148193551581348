/* eslint-disable lines-between-class-members */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';
import { Notification } from './notification.model';
import { AuthenticationService } from '../sharedServices/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../core/services/storage/storage.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export default class NotificationsComponent implements OnInit, OnDestroy {
  currentUser;
  language:string = localStorage.getItem("language")
  notifications: Notification[] = [
    // {
    //   category: "Wine added",
    //   cellar_bk_id: "c21b8490-aec5-4244-b8db-4ac46402338a",
    //   id: "663a36d80a7dea959a58207fb0257ede",
    //   invoker_user_id: "0618dc42-9065-4b76-976e-232ff77ea02d",
    //   label: "Akshay Prabhudesai has added new Wine: Drapier, Musigny Grand Cru",
    //   user_wine_id: "83345e96-4a33-4331-9b5e-8f5ef5f656c0",
    // }
  ];
  notificationSub;
  showNotifications = false;
  currentTime: number;

  constructor(
    private notificationService: NotificationsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private storageService: StorageService,
  ) {
    this.authenticationService.currentUser.subscribe((user) => this.currentUser = user);
  }

  ngOnInit(): void {
    this.notificationService.getUnreadNotifications().subscribe((notifications) => {
      this.displayNotification(notifications.data);
      this.translate.onLangChange.subscribe((data) => {
        this.language = data.lang;
        this.displayNotification(notifications.data);
      });
    });

    this.notificationSub = this.notificationService.getNotifications(this.currentUser.username)
      .subscribe({
        next: (data) => {
          const response = data.data.onCreateNotification;
          this.processNotification(response);
          this.notifications = this.notifications.sort((a, b) => a.duration - b.duration);
        },
        error: (error) => {
          console.warn(error);
        },
      }); 
  }

  displayNotification(data:any){
    const response = data.getNotification;
    if(response){
      this.processOfflineNotifications(response)
      this.notifications = this.notifications.sort((a, b) => a.duration - b.duration);
    }
  }

  processNotification(response): void {
    if (response.invoker_user_id !== this.currentUser.username) {
      let notificationMsg = '';
      const payload = response.payload[0];
      const invokerName = `${response.invoker_firstname} ${response.invoker_lastname}`;
      switch (response.category) {
        case 'Kellerbook created':
          notificationMsg = `${invokerName} `+ this.translate.instant("has added new Kellerbook") +`: ${payload.cellar_bk_nm}`;
          break;
        case 'Kellerbook updated':
          notificationMsg = `${invokerName} `+ this.translate.instant("has updated Kellerbook") +`: ${payload.cellar_bk_nm}`;
          break;
        case 'Wine added':
          notificationMsg = `${invokerName} `+ this.translate.instant("has added new Wine") +`: ${payload.wine_name}`;
          break;
        case 'Wine updated':
          notificationMsg = `${invokerName} `+this.translate.instant("has updated Wine") +`: ${payload.wine_name}`;
          break;
        case 'Wine deleted':
          notificationMsg = `${invokerName} `+this.translate.instant("common.has_deleted_wine") +`: ${payload.wine_name}`;
          break;
        case 'New Friend Request':
          notificationMsg = this.translate.instant("You have a new Friend Request from") +` ${invokerName}`;
          break;
        case 'Friend Request Accepted':
          notificationMsg = `${invokerName} `+ this.translate.instant("has accepted your Friend Request");
          break;
        case 'Invitation to the community':
          if (this.language === "DE") {
            notificationMsg = `Sie werden von ${invokerName} in die Gruppe ${payload.community_name} eingeladen`;
          } else {
            notificationMsg = `You are invited to a community: ${payload.community_name} by ${invokerName}`;
          }
          break;
        case 'Invitation to the community accepted':
          // if (this.language === "DE") {
          //   notificationMsg = `${invokerName} has accepted your invitation to community: ${payload.community_name}`;
          // } else {
            notificationMsg = `${invokerName} has accepted your invitation to community: ${payload.community_name}`;
          // }
          break;  
        case 'New Post':
          if (this.language === "DE") {
            notificationMsg = `${invokerName} hat einen neuen Post in Gruppe ${payload.community_name} erstellt`
          } else {
            notificationMsg = `${invokerName} has created a new post in community: ${payload.community_name}`;
          }
          break;
        case 'Post Reaction':
          notificationMsg = `${invokerName} `+this.translate.instant("has liked your post");
          break;
        case 'New Comment':
          notificationMsg = `${invokerName} `+this.translate.instant("has commented on your post");
          break;
        case 'Comment Reaction':
          notificationMsg = `${invokerName} `+this.translate.instant("has liked your comment");
          break;
        case 'Sale Added':
          notificationMsg = `${invokerName} is selling ${payload.wine_name} wine at ${payload.sell_price} €`;
          break;
        case 'New Bid Added':
          notificationMsg = `A bid is placed on My Sale offer ${payload.wine_name}`;
          break;
        case 'Bid Rejected By Seller':
          notificationMsg = `Bid on ${payload.wine_name} wine is not accepted`;
          break;
        case 'Bid Accepted':
          notificationMsg = `Bid on ${payload.wine_name} wine is accepted`;
          break;
        case 'Bid Deleted By Bidder':
          notificationMsg = `Bid on ${payload.wine_name} wine is deleted by bidder`;
          break;  
        case 'Sale Settele By Buyer':
          notificationMsg = `Sale Offer for ${payload.wine_name} is settled`;
          break; 
        case 'Sale Settele For Seller':
          notificationMsg = `Sale Offer for ${payload.wine_name} is settled`;
          break;
        case 'Sale bid view expired':
          notificationMsg = `You’ve lost access to view bids for your ${payload.wine_name} wine sale. To regain this access, please pay using VinoCoin.`;
          break;
        case 'Level Upgrade':
          notificationMsg = 'LEVEL UPGRADED!!!';
          break;
        default:
          notificationMsg = this.translate.instant("You have a new Notification from") +` ${invokerName}`;
          break;
      }
      let durationInSecond = this.getNotificationDuration(response.created_timestamp); 
      this.notifications.push(
        new Notification(
          notificationMsg,
          response.event_id,
          response.category,
          payload?.cellar_bk_id,
          payload?.user_wine_id,
          response.invoker_user_id,
          response.created_timestamp,
          payload?.community_id,
          "",
          payload?.sales_id,
          payload?.bid_detail_id,
          durationInSecond
        ),
      );
    }
  }

  processOfflineNotifications(response): void {
    const offlineNotifications = response;
    let notificationsNew:Notification[] = []
    this.currentTime = new Date().getTime();
    for (const notification of offlineNotifications) {
      let notificationMsg = '';
      const payload = notification.payload;
      const invokerName = `${notification.invoker_user_info.firstname} ${notification.invoker_user_info.lastname}`;
      switch (notification.category) {
        case 'Kellerbook created':
          notificationMsg = `${invokerName} `+ this.translate.instant("has added new Kellerbook") +`: ${payload.cellar_bk_nm}`;
          break;
        case 'Kellerbook updated':
          notificationMsg = `${invokerName} `+ this.translate.instant("has updated Kellerbook") +`: ${payload.cellar_bk_nm}`;
          break;
        case 'Wine added':
          notificationMsg = `${invokerName} `+ this.translate.instant("has added new Wine") +`: ${payload.wine_name}`;
          break;
        case 'Wine updated':
          notificationMsg = `${invokerName} `+ this.translate.instant("has updated Wine") +`: ${payload.wine_name}`;
          break;
        case 'Wine deleted':
          notificationMsg = `${invokerName} `+ this.translate.instant("common.has_deleted_wine") +`: ${payload.wine_name}`;
          break;  
        case 'New Friend Request':
          notificationMsg = this.translate.instant("You have a new Friend Request from") +` ${invokerName}`;
          break;
        case 'Friend Request Accepted':
          notificationMsg = `${invokerName} `+ this.translate.instant("has accepted your Friend Request");
          break;
        case 'Invitation to the community':
          if (this.language === "DE") {
            notificationMsg = `Sie werden von ${invokerName} in die Gruppe ${payload.community_name} eingeladen`;
          } else {
            notificationMsg = `You are invited to a community: ${payload.community_name} by ${invokerName}`;
          }
          break;
        case 'Invitation to the community accepted':
          if (this.language === "DE") {
            notificationMsg = `${invokerName} has accepted your invitation to community: ${payload.community_name}`;
          } else {
            notificationMsg = `${invokerName} has accepted your invitation to community: ${payload.community_name}`;
          }
          break;
        case 'New Post':
          if (this.language === "DE") {
            notificationMsg = `${invokerName} hat einen neuen Post in Gruppe ${payload.community_name} erstellt`
          } else {
            notificationMsg = `${invokerName} has created a new post in community: ${payload.community_name}`;
          }
          break;
        case 'Post Reaction':
          notificationMsg = `${invokerName} `+this.translate.instant("has liked your post");
          break;
        case 'New Comment':
          notificationMsg = `${invokerName} `+this.translate.instant("has commented on your post");
          break;
        case 'Comment Reaction':
          notificationMsg = `${invokerName} `+this.translate.instant("has liked your comment");
          break;
        case 'Sale Added':
          notificationMsg = `${invokerName} is selling ${payload.wine_name} wine at ${payload.sell_price} €`;
          break;
        case 'New Bid Added':
          notificationMsg = `A bid is placed on My Sale offer ${payload.wine_name}`;
          break;
        case 'Bid Rejected By Seller':
          notificationMsg = `Bid on ${payload.wine_name} wine is not accepted`;
          break;
        case 'Bid Accepted':
          notificationMsg = `Bid on ${payload.wine_name} wine is accepted`;
          break;
        case 'Bid Deleted By Bidder':
          notificationMsg = `Bid on ${payload.wine_name} wine is deleted`;
          break;  
        case 'Sale Settele By Buyer':
          notificationMsg = `Sale Offer for ${payload.wine_name} is settled`;
          break; 
        case 'Sale Settele For Seller':
          notificationMsg = `Sale Offer for ${payload.wine_name} is settled`;
          break; 
        case 'Sale bid view expired':
          notificationMsg = `You’ve lost access to view bids for your ${payload.wine_name} wine sale. To regain this access, please pay using VinoCoin.`;
          break;
        case 'Level Upgrade':
          notificationMsg = 'LEVEL UPGRADED!!!';
          break;
        default:
          notificationMsg = this.translate.instant("You have a new Notification from") +` ${invokerName}`;
          break;
      }
      let durationInSecond = this.getNotificationDuration(notification.created_timestamp);  
      notificationsNew.push(
        new Notification(
          notificationMsg,
          notification.event_id,
          notification.category,
          notification.payload?.cellar_bk_id,
          notification.payload?.user_wine_id,
          notification.invoker_user_id,
          notification.created_timestamp,
          notification.payload?.community_id,
          notification.invoker_user_info.firstname,
          notification.payload?.sales_id,
          notification.payload?.bid_detail_id,
          durationInSecond
        ),
      );  
    }
    this.notifications = notificationsNew;
    }
  
  getNotificationDuration(created_timestamp: any) {
    const notificationDate = new Date(created_timestamp + ' UTC').getTime();
    const seconds = (this.currentTime - notificationDate) / 1000;
    return seconds;
  }

  handleNotification(notification, index): void {
    this.notificationService.setNotificationAsRead(notification.id).subscribe(({data}) => {
    },(error) => {
      console.log('there was an error sending the query', error);
    });
    this.storageService.setLocalStorage('friendName', notification?.userName);
    let routePath = 'dashboard';
    let fragment = null;
    let isQuery = null;
    switch (notification.category) {
      case 'Kellerbook created':
      case 'Kellerbook updated':
      case 'Wine deleted':  
        // this.storageService.setLocalStorage('friendName', data.firstname);
        // this.storageService.setLocalStorage('friendPictureId', data.picture);
        // this.storageService.setLocalStorage('friendIdentityId', data.identityId);
        routePath = `/friends/${notification.invoker_user_id}/cellarbook/${notification.cellar_bk_id}`;
        break;
      case 'Wine added':
      case 'Wine updated':
        // this.storageService.setLocalStorage('friendName', data.firstname);
        // this.storageService.setLocalStorage('friendPictureId', data.picture);
        // this.storageService.setLocalStorage('friendIdentityId', data.identityId);
        routePath = `/friends/${notification.invoker_user_id}/cellarbook/${notification.cellar_bk_id}/view-wine/${notification.user_wine_id}`;
        break;
      case 'New Friend Request':
        routePath = 'friends';
        fragment = 'friendRequests';
        break;
      case 'Friend Request Accepted':
        routePath = 'friends';
        fragment = 'friendList';
        break;
      case 'Invitation to the community':
        routePath = 'communities/list';
        fragment = 'pendingRequests';
        break;
      case 'New Post':
      case 'Post Reaction':
      case 'New Comment':
      case 'Comment Reaction':
      case 'Invitation to the community accepted':  
        routePath = `communities/${notification.community_id}`;
        break;
      case 'Sale Added':
        routePath = 'marketplace';
        fragment = 'offers';
        isQuery = {"saleid": notification?.sales_id};   
        break;       
      case 'Sale Settele':
        break;
      case 'Bid Deleted By Bidder':
      case 'Bid Rejected By Seller':
      case 'New Bid Added':  
        routePath = 'marketplace';
        fragment = 'mySales';
        isQuery = {"saleid": notification?.sales_id};
        break;   
      case 'Bid Accepted':  
        routePath = 'marketplace';
        fragment = 'myBids';
        isQuery = {"bidid": notification?.bid_detail_id, "saleid": notification?.sales_id};
        break;  
      case 'Sale Settele By Buyer':   
        routePath = 'marketplace';
        fragment = 'myBids'; 
        isQuery = {"bidid": notification?.bid_detail_id, "saleid": notification?.sales_id};
        break;
      case 'Sale Settele For Seller':  
        routePath = 'marketplace';
        fragment = 'mySales';  
        isQuery = {"saleid": notification?.sales_id};      
        break;     
      case 'Sale bid view expired':  
        routePath = 'marketplace';
        fragment = 'mySales';  
        isQuery = {"saleid": notification?.sales_id};      
        break;
      case 'Level Upgrade':
        alert('LEVEL UPGRADED!!!');
        break;
      default:
        break;
    }
    if (fragment !== null && isQuery !== null) {
      this.router.navigate([routePath], { fragment: fragment, queryParams: isQuery });
    }
    else if (fragment !== null) {
      this.router.navigate([routePath], { fragment });
    } else {
      this.router.navigate([routePath]);
    }
    this.removeNotification(index);
  }

  markAsReadAndRemove(notification: any, index: number): void {
    this.notificationService.setNotificationAsRead(notification.id).subscribe(({data}) => {
      this.removeNotification(index);
    },(error) => {
      console.log('there was an error sending the query', error);
    });
  }

  removeNotification(index): void {
    this.notifications.splice(index, 1);
  }

  clearAllNotifications(): void {
    this.notifications.forEach((item) => {
      this.notificationService.setNotificationAsRead(item.id).subscribe();
    })
    this.notifications.splice(0, this.notifications.length)
  }

  showHideNotifications(): void {
    this.showNotifications = !this.showNotifications;
  }

  onClickOutNotifications(): void {
    this.showNotifications = false;
  }

  ngOnDestroy(): void {
    this.notificationSub.unsubscribe();
  }
}
