import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.css']
})
export class SingleSelectDropdownComponent implements OnInit, OnChanges {
  @Input() fieldLabel: string = '';
  @Input() options: any[] = [];
  @Input() labelKey: string = '';
  @Input() labelValue: string = '';
  @Input() class: string = '';
  @Input() requiredErrorMsg: string = '';
  @Input() isRequired = false;
  @Input() searchRequired = false;
  @Input() dropdownControl!: FormControl; // Accept FormControl from parent
  @Input() appearance = '';

  @Output() onSelect = new EventEmitter();

  searchControl = new FormControl('');
  filteredOptions: any[] = [];

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  
  constructor() {
    this.searchControl.valueChanges.subscribe(value => {
      if (value !== '') {
        this.filteredOptions = this.options.filter(option =>
          option[this.labelValue].toLowerCase().includes(value.toLowerCase())
        );
      }
    });
  }

  ngOnInit(): void {
    this.filteredOptions = this.options?.slice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['options']?.['currentValue']?.length) {
      this.options = changes?.['options']['currentValue'];
      this.filteredOptions = this.options;
    }
    if (changes?.['isRequired']?.['currentValue'] === true) {
      this.dropdownControl.setValidators([Validators.required]);
      this.dropdownControl.updateValueAndValidity();
    }
  }

  focusSearchInput(isOpened: boolean): void {
    if (!isOpened) {
      this.clearInput();
    } else {
      setTimeout(() => {
        this.searchInput.nativeElement.focus(); // Focus on the input after dropdown opens
      }, 0); // Delay to ensure it runs after the dropdown opens
    }
  }

  onInputFocus(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); // Prevents default scrolling
  }

  clearInput(): void {
    this.searchControl.setValue('');
    this.filteredOptions = this.options.slice();
  }

  onValueSelect(event: any): void {
    this.clearInput();
    this.onSelect.emit(event.value);
  }
}
