<div class="cookie-consent-container d-flex align-items-center text-center scroll-sm" *ngIf="!cookieAccepted">
    <div class="cookie-consent-wrapper flex-column">
        <img src="../../../../assets/img/cookie.svg" alt="cookie image">
        <div class="message my-3">
            <div class="english-text" *ngIf="currentLanguage === 'EN'">
                <h4>Tresorvino currently uses cookies for two different purposes.</h4>
                <p>On the one hand, the use of the platform is only permitted for persons aged 18 and over only and we store the request result in a cookie.</p>
                <p>Secondly, we want to enable you to make the best possible use of our website and to continuously improve our website. We can also use it to show you usage-based content and advertising and work together with selected partners (including Google) for this purpose. You will also receive advertising on other websites through these partners.</p>
                <p>You can revoke your voluntary consent at any time. Further information (including on data transfers) and setting options can be found under "My profile" and in our privacy policy.
                </p>
            </div>
            <div class="german-text" *ngIf="currentLanguage === 'DE'">
                <h4>Tresorvino nutzt Cookies aktuell für zwei verschiedene Zwecke.</h4>
                <p>Zum einen ist die Nutzung der Plattform nur für Personen ab 18 Jahre gestattet und wir speichern die einmalige Abfrage des Alters in einem Cookie.</p>
                <p>Ferner möchten wir Ihnen die bestmögliche Nutzung unserer Webseite ermöglichen, sowie unsere Webseite fortlaufend verbessern. Auch können wir Ihnen damit nutzungsbasierte Inhalte und Werbung anzeigen und arbeiten dafür mit ausgewählten Partnern (u. a. Google) zusammen. Durch diese Partner erhalten Sie auch Werbung auf anderen Webseiten.</p>
                <p>Sie können Ihre freiwillige Zustimmung jederzeit widerrufen. Weitere Informationen (auch zu Datenübermittlungen) und Einstellungsmöglichkeiten finden Sie unter "Mein Profil" und in unseren Datenschutzhinweisen.</p>
            </div>
        </div>
        <div class="buttons">
            <button class="btn tr-btn-primary btn-accept mr-2" (click)="acceptRejectCookies(true)">Yes</button>
            <button class="btn tr-btn-primary btn-accept" (click)="acceptRejectCookies(false)">No</button>
        </div>
    </div>
</div>
<div class="backdrop" *ngIf="!cookieAccepted"></div>