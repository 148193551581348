/* eslint-disable lines-between-class-members */
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css'],
})
export default class ShareComponent implements OnInit {
  pageUrl = '';
  copyLink = false;
  copyBtnText = 'Copy';
  showLink = true;
  user: any = {};

  constructor(private authService: AuthenticationService,) {}

  ngOnInit(): void {
    this.pageUrl = `${window.location.origin}/registration`;
  }

  onShareClick(): void {
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      this.pageUrl = `${window.location.origin}/registration?reff=${this.user.username}`;
    });
    this.copyLink = !this.copyLink;
  }

  onLinkCopy(): void {
    this.showLink = false;
    const copyText: any = document.querySelector('#copyTextInput');
    copyText.select();
    document.execCommand('copy');
    this.copyBtnText = 'Copied';
    setTimeout(() => {
      this.showLink = true;
      this.copyBtnText = 'Copy';
      this.copyLink = false;
    }, 2000);
  }

  onClickedOutside(): void {
    this.copyLink = false;
  }
}
