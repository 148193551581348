export const environment = {
  production: false,

  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:204a78b4-8ba0-4c8e-856a-c78dac2b980b',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_Z6V10kEFk',
  aws_user_pools_web_client_id: '1vn20a8hi260fi74a8ga5ciimm',
  oauth: {
    domain: 'tresorvino-dev.auth.eu-central-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://www.dev.tresorvino.de/dashboard/',
    redirectSignOut: 'https://www.dev.tresorvino.de/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://dev.api.tresorvino.de/graphql',
  aws_appsync_graphqlWSEndpoint: 'https://u2muje3c7bg2jo4umhve3k3n2i.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: '4cbqk37j6nb43pjjvqim6zhsz4',
  Storage: {
    AWSS3: {
      bucket: 'dev-tresorvino-users-photos', // REQUIRED -  Amazon S3 bucket name
      region: 'eu-central-1', // OPTIONAL -  Amazon service region
    },
  },
  payment: {
    paypal: {
      clientId: 'ATW2Br0hWGLoG9K2W6A0Sa_9GupkMgu_k5X4SSuMAu_ylW4Pa4n4R47IGxt4OAoED3R-WlQizC2_IsDJ',
    },
    stripe: {
      secretKey: 'sk_test_51Nt5bGCteHrC7wpHVxVtamYc98cqfgfU9SqgxnWIUXJUPFoWBIYFfRe3EpplAsP6jCrZAaZQrNDJErDvMg2tTk4X00SgN5HOgo',
      publishableKey: 'pk_test_51Nt5bGCteHrC7wpHbDXlLJfVeeolkxydE90GuiyR9NXVlkPMkFIFJ5wmYDAbCU08U5N0JtqwPBpefwwELg4pXbN800t0i2wl61'
    }
  },
  countryStateCityAPIKey: 'RmRBNkRTWlhGQkI2dENEQTRRTjk0a1hjTXhQZnVTeUI2UDZFejNnSg==',
  APIKey: 'E0giwCbB2e27dXMu4ZicM7KdMpSVbw7p20bldcFG',
  postalCodeEndpoint: 'https://w2vxu34okj.execute-api.eu-central-1.amazonaws.com/dev/postalcode',
  searchUserExistEndpoint: 'https://w2vxu34okj.execute-api.eu-central-1.amazonaws.com/dev/search-user',
  referralRegisterEndpoint: 'https://w2vxu34okj.execute-api.eu-central-1.amazonaws.com/dev/user-referral',
};
